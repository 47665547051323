<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header errors">
            <!-- Left -->
            <div class="flexH width">
                <a class="icon iconButton" @click.prevent="$router.push('/home')">
                    <font-awesome-icon icon="fas fa-chevron-left"></font-awesome-icon>
                </a>
            </div>
            <!-- Right -->
            <div class="flexH width right"></div>
        </section>
        <!-- Main -->
        <div class="main errors">
            <div class="img"><img src="@/assets/icon/error.svg" /></div>
            <div class="flexV center">
                <span class="fXXL white">掃碼錯誤</span>
                <span class="fL secondary"><br />此條碼已被使用，請持新的條碼</span>
                <span class="fL secondary">回到掃碼頁重新嘗試</span>
            </div>
            <a class="button fit rounded white" @click.prevent="$router.push('/scanner')"
                ><span class="fL primary">回掃碼頁</span></a
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {};
    },
    methods: {
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        }
    }
};
</script>
<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>